(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-marketplace-shares/assets/marketplace-shares.js') >= 0) return;  svs.modules.push('/components/payment/middleware-marketplace-shares/assets/marketplace-shares.js');
'use strict';

const {
  logger
} = svs.components.payment.common;
const {
  getAccountBalance
} = svs.components.marketplaceShared.getBalance;
const {
  MiddlewarePaymentError
} = svs.components.payment.common.error;
const marketplaceShares = onInteraction => _ref => {
  let {
    payment
  } = _ref;
  return async next => {
    try {
      let isChangedBalance = false;
      let numberOfNewShares;
      let balance;
      for (const payable of payment.getPayableItems()) {
        const activatedDrawId = payable.activatedDrawId;
        if (!activatedDrawId) {
          continue;
        }
        const drawNumber = payable.bets[0].drawNumber;
        const productId = payable.bets[0].productId;
        const role = payable.role;
        ({
          isChangedBalance,
          numberOfNewShares,
          balance
        } = await getAccountBalance.calculateNewShares(role, productId, drawNumber, activatedDrawId));
      }
      if (isChangedBalance) {
        logger.info("Notify updated balance. New balance: ".concat(balance, ", numNewShares: ").concat(numberOfNewShares));
        await onInteraction({
          numberOfNewShares,
          balance
        });
      }
      next();
    } catch (err) {
      logger.debug(err === null || err === void 0 ? void 0 : err.message, err);
      const mwError = new MiddlewarePaymentError({
        clientCode: MiddlewarePaymentError.ErrorCodes.PAYMENT_ABORTED_MP_SHARES
      });
      next(mwError);
    }
  };
};
setGlobal('svs.components.payment.middleware.marketplaceShares', marketplaceShares);

 })(window);